import "../css/Works.css"
import WorkData from "./WorkData"
import work1 from '../assets/about.png'
import work2 from '../assets/services.png'
import work3 from '../assets/profile.png'


function Works(){
    return(
        <div className="works">
            <h1>My Recent Works</h1>
            <p>Here are some of most recent works and projects</p>
        <div className="workcard">
            <WorkData 
            image = {work1}
            heading = "Web Development"
            text = "Need a website for your company or business? Yole-Tech is the perfect solution"
            />
            <WorkData 
            image = {work2}
            heading = "Software Development"
            text = "Need a software for your company or business? Yole-Tech is the perfect solution"
            />
            <WorkData 
            image = {work3}
            heading = "Tutorials"
            text = "I offer tutorials on different programming languages and modern web Development Technologies"
            />
        </div>
        </div>
    )
}

export default Works