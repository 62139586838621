import Hero from "../components/Hero"
import Navbar from "../components/Navbar"
import about from "../assets/about.png"
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";

function About () {
    return (
        <>
        <Navbar />
        <Hero 
        cName = "about"
        heroImg = {about}
        title = "ABOUT US"
        text = "Our Mission & Vision"
        buttonText = "Read More"
        url = "/about"
        btnClass = "show"
        />
        <AboutUs />
        <Footer />
        </>
    )
}

export default About;