import Hero from "../components/Hero"
import Works from "../components/Works"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import services from "../assets/services.png"

function Services () {
    return (
        <>
        <Navbar />
        <Hero 
        cName = "services"
        heroImg = {services}
        title = "WHAT WE DO"
        text = "Here are some of the services we render"
        buttonText = "Get Started"
        url = "https://forms.gle/MfQQYykMF3u89D7e6"
        btnClass = "show"
        />
        <Works />
        <Footer />
        </>
    )
}

export default Services;