import Hero from "../components/Hero"
import Navbar from "../components/Navbar"
import contact from "../assets/contact.png"
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

function Contact () {
    return (
        <>
        <Navbar />
        <Hero 
        cName = "contact"
        heroImg = {contact}
        title = "CONTACT US"
        text = "Got a Job for us?"
        buttonText = "Get Started"
        url = "https://forms.gle/MfQQYykMF3u89D7e6"
        btnClass = "show"
        />
        <ContactForm />
        <Footer />
        </>
    )
}

export default Contact;