import '../css/Contactform.css'

function ContactForm(){
    return (
        <div className='form-container'>
            <h1>Have a Project for us?</h1>
            <p>Send us a message</p>

            <form>
                <input type="text" placeholder='Name'/>
                <input type="text" placeholder='Email'/>
                <input type="text" placeholder='Subject'/>
                <textarea placeholder='Message' rows='4'></textarea>
                <button>Send Message</button>
            </form>
        </div>
    )
}

export default ContactForm