import Hero from "../components/Hero"
import Navbar from "../components/Navbar"
import profile from "../assets/profile.png"
import Projects from "../components/Projects";
import Works from "../components/Works";
import Footer from "../components/Footer";

function Home () {
    return (
        <>
        <Navbar />
        <Hero 
        cName = "hero"
        heroImg = {profile}
        title = "YOLE-TECH HUB"
        text = "We Are Specialised in Website Development, Software Development and Data Analysis"
        buttonText = "Get Started"
        url = "https://forms.gle/MfQQYykMF3u89D7e6"
        btnClass = "show"
        />
        <Projects />
        <Works />
        <Footer />
        </>
    )
}

export default Home;