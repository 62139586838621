import Navbar from "../components/Navbar"
import Footer from "../components/Footer";
import Works from '../components/Works'
import Hero from "../components/Hero";
import about from '../assets/about.png'

function Tutorial () {
    return (
        <>
        <Navbar />
        
        <Hero 
        cName = "about"
        heroImg = {about}
        title = "MY TUTORIALS"
        text = "My Most Recent Tutorials"
        buttonText = "Visit My Channel"
        url = "https://www.youtube.com/@yole-techhub5167/videos"
        btnClass = "show"
        />

        <Works />
        
        <Footer />
        </>
    )
}

export default Tutorial;