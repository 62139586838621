import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../css/Contactform.css'
import contact from "../assets/contact.png"
import Hero from '../components/Hero'

function SignUp(){
    return (
        <>
        <Navbar/>
        <Hero 
        cName = "signup"
        heroImg = {contact}
        title = "Join My Training Program"
        text = "Please Register to Get Started"
        buttonText = "Register"
        url = "https://forms.gle/MfQQYykMF3u89D7e6"
        btnClass = "show"
        />
        <div className='form-container'>
            <form>
                <input type="text" placeholder='First Name'/>
                <input type="text" placeholder='Last Name'/>
                <input type="text" placeholder='Email'/>
                <input type="password" placeholder='Password'/>
                <input type="submit" value='Register'/>
            </form>
        </div>
        <Footer/>
        </>
    )
}

export default SignUp