import ProjectData from './ProjectData';
import '../css/Projects.css'
import services from "../assets/services.png"
import profile from '../assets/profile.png'
import about from '../assets/about.png'
import hero from '../assets/hero.png'

const Projects = () => {
    return (
        <div className="projects">
            <h1>Projects</h1>
            <p>My Most Recent Relevant Projects.</p>
            <ProjectData className="first-pro"
            heading = "My First Project"
            text = "The UMD links use the latest tag to point to the latest version of the library. This pointer is unstable and subject to change as we release new versions. My Most Recent Relevant Projects. The UMD links use the latest tag to point to the latest version of the library. This pointer is unstable and subject to change as we release new versions."
            img1 = {services}
            img2 = {profile}
            />

            <ProjectData className="first-pro-reverse"
           heading = "My First Project"
            text = "The UMD links use the latest tag to point to the latest version of the library. This pointer is unstable and subject to change as we release new versions. My Most Recent Relevant Projects. The UMD links use the latest tag to point to the latest version of the library. This pointer is unstable and subject to change as we release new versions."
            img1 = {about}
            img2 = {hero}
            />

        </div>
    )
}

export default Projects;