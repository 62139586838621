import React, { Component } from 'react'
import '../css/Projects.css'

class ProjectData extends Component {
  render() {
    return (
        <div className={this.props.className}>
        <div className="pro-text">
        <h2>{this.props.heading}</h2>
        <p>{this.props.text}</p>
        </div>

        <div className="image">
            <img src={this.props.img1} alt=""/>
            <img src={this.props.img2} alt=""/>
        </div>
    </div>
    )
  }
}

export default ProjectData