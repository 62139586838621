import '../css/About.css'

function AboutUs () {
    return (
        <div className='about-container'>
            <h1>Our History</h1>
            <p>How we started</p>
            <h1>Our Mission</h1>
            <p>What we are here to do</p>
            <h1>Our Vision</h1>
            <p>Our future expectations</p>
            <h1>Our Story</h1>
            <p>What we have done so far is a splendid experience in the software industry</p>
        </div>
    )
}

export default AboutUs