import '../css/Footer.css'

const Footer = () => {
    return (
<div className='footer'>

        <div className='top'>
        <div>
            <h1>YOLE-TECH</h1>
            <p>Website & Software Developer</p>
        </div>
        <div>
            <a href="https://facebook.com/Eniekpo" target='_blank' rel='noreferrer noopener'><i className='fa-brands fa-facebook-square'></i></a>
            <a href="https://instagram.com/Eniekpo12" target='_blank' rel='noreferrer noopener'><i className='fa-brands fa-instagram-square'></i></a>
            <a href="https://linkedin.com/in/Eniekpo" target='_blank' rel='noreferrer noopener'><i className="fa-brands fa-linkedin"></i></a>
            <a href="https://wa.me/+2347032767758" target='_blank' rel='noreferrer noopener'><i className='fa-brands fa-whatsapp-square'></i></a>
            <a href="https://www.youtube.com/@yole-techhub5167/videos" target='_blank' rel='noreferrer noopener'><i className='fa-brands fa-youtube-square'></i></a>
        </div>
        </div>

        <div className='bottom'>

        <div>
            <h4>Services</h4>
            <a href='https://forms.gle/MfQQYykMF3u89D7e6'>Data Analysis</a>
            <a href='https://forms.gle/MfQQYykMF3u89D7e6'>Web Development</a>
            <a href='https://forms.gle/MfQQYykMF3u89D7e6'>Software Development</a>
            <a href='https://forms.gle/MfQQYykMF3u89D7e6'>Project Codes</a>
        </div>

        <div>
            <h4>Community</h4>
            <a href="https://www.github.com/Eniekpo" target='_blank' rel='noreferrer noopener'>Github</a>
            <a href='/'>Issues</a>
            <a href='/'>Project</a>
            <a href='/'>Twitter</a>
        </div>

        <div>
            <h4>Help</h4>
            <a href='https://forms.gle/MfQQYykMF3u89D7e6'>Support</a>
            <a  href="https://wa.me/+2347032767758" target='_blank' rel='noreferrer noopener'>WhatsApp</a>
            <a href='https://forms.gle/MfQQYykMF3u89D7e6'>Contact Us</a>
        </div>

        <div>
            <h4>Others</h4>
            <a href='/'>Terms of Service</a>
            <a href='/'>Privacy Policy</a>
            <a href='/'>Feed Back</a>
        </div>

        </div>
</div>
    )
}

export default Footer