import "./index.css"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Home from "./routes/Home"
import About from "./routes/About"
import Services from "./routes/Services"
import Tutorial from "./routes/Tutorial"
import Contact from "./routes/Contact"
import SignUp from "./routes/SignUp"

export default function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/tutorial" element={<Tutorial/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/register" element={<SignUp/>}/>
                </Routes>
            </Router>
        </div>
    )
}