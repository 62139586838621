export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
    icon:  "fa-solid fa-house-user"
  },
  {
    title: "About",
    url: "/about",
    cName: "nav-links",
    icon: "fa-solid fa-circle-info"
  },
  {
    title: "Services",
    url: "/services",
    cName: "nav-links",
    icon: "fa-solid fa-briefcase"
  },
  {
    title: "Tutorials",
    url: "/tutorial",
    cName: "nav-links",
    icon: "fa-solid fa-book"
  },
  {
    title: "Contact",
    url: "/contact",
    cName: "nav-links",
    icon: "fa-solid fa-address-book"
  },
  {
    title: "Register",
    url: "/register",
    cName: "nav-links",
    icon: "fa-solid fa-user"
  }
];
