import "../css/Hero.css"

function Hero(props) {
    return (
        <>
            <div className={props.cName}>
                <img alt="Hero" src={props.heroImg} />
            </div>
            <div className="hero-text">
            <h1>{props.title}</h1>
            <p>{props.text}</p>
            <a href={props.url} className={props.btnClass} target='_blank' rel='noreferrer noopener'>{props.buttonText}</a>
            </div>
        </>
    )
}

export default Hero;